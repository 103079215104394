import React, { useEffect, useRef, useState } from "react";
import OdiaFsm from "../lib/odiaFsm";

export default function OdiaInput({
  type = "text",
  onChange = () => 0,
  onClick = () => 0,
  onFocus = () => 0,
  value,
  ...rest
}) {
  const inputRef = useRef(null);
  const [fsm] = useState(new OdiaFsm());
  const [pos, setPos] = useState(null);
  const [hasHidden, setHasHidden] = useState(false);

  useEffect(() => {
    if (inputRef.current) inputRef.current.value = value || "";
  }, [value]);

  function handleKeyDown(e) {
    const val = e.target.value;
    if (val === "" && !hasHidden) {
      fsm.reset();
    }
    let newHasHidden = false;

    if (e.ctrlKey && e.keyCode === 32) {
      e.preventDefault();
      fsm.toggleLang();
      setHasHidden(newHasHidden);
      return;
    }

    if (e.altKey || e.ctrlKey || e.metaKey) {
      setHasHidden(newHasHidden);
      setPos(e.target.selectionStart);
      return;
    }
    const char = e.key;
    if (char.length !== 1) {
      if (e.target.selectionStart !== pos || char.startsWith("Arrow")) {
        fsm.reset();
      }
      setPos(e.target.selectionStart);
      setHasHidden(newHasHidden);
      return;
    }

    e.preventDefault();
    let [dist, str] = fsm.input(char);
    if (str === null) {
      newHasHidden = true;
      str = "";
    }
    const start = e.target.selectionStart;
    const left = val.substring(0, start + dist);
    const right = val.substring(start, val.length);
    const end = str !== undefined ? start + dist + str.length : start + dist;

    e.target.value = left + str + right;
    e.target.setSelectionRange(end, end);
    onChange(e.target.value);
    setPos(e.target.selectionStart);
    setHasHidden(newHasHidden);
  }

  function handleChange(e) {
    onChange(e.target.value);
  }

  function handleFocus(e) {
    if (e.target.selectionStart !== pos) {
      fsm.reset();
    }
    setPos(e.target.selectionStart);
    onFocus(e);
  }

  function handleClick(e) {
    if (e.target.selectionStart !== pos) {
      fsm.reset();
    }
    setPos(e.target.selectionStart);
    onClick(e);
  }

  return (
    <input
      type={type}
      ref={inputRef}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      onFocus={handleFocus}
      onClick={handleClick}
      {...rest}
    />
  );
}
