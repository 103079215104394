import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SimpleMDE from "simplemde";
import showdown from "showdown";
import Typo from "typo-js";
import OdiaFsm from "../lib/odiaFsm";

import "simplemde/dist/simplemde.min.css";

const converter = new showdown.Converter({
  omitExtraWLInCodeBlocks: true,
  noHeaderId: true,
  tables: true,
  strikethrough: true,
  smoothLivePreview: true,
  simpleLineBreaks: true,
  requireSpaceBeforeHeadingText: true,
  underline: true,
  disableForced4SpacesIndentedSublists: true,
});

const EditorContainer = styled.div`
  flex-grow: 1;
  height: calc(100vh - 66px);
  position: relative;

  .CodeMirror,
  .CodeMirror-scroll {
    height: calc(100vh - 130px);
    border: none;
    font-size: 16px;

    .CodeMirror-sizer {
      padding: 16px 16px;
    }
  }

  .editor-preview {
    padding: 24px 24px;
  }

  .editor-toolbar {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 64px;

    border: none;
    border-bottom: 1px rgba(32, 33, 36, 0.1) solid;
    padding: 0 16px;

    a {
      color: black !important;
    }
  }

  .editor-toolbar i.separator {
    height: 64px;
    border: none;
    border-left: 1px rgba(32, 33, 36, 0.1) solid;
    margin: 0 8px;
  }

  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 20px;
  }
  h3 {
    font-size: 18px;
  }
  p {
    font-size: 16px;
  }
`;

const EditorTextarea = styled.textarea`
  &:focus,
  &:active {
    outline: none;
  }
`;

const SavingContainer = styled.div`
  height: 16px;
  font-size: 12px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  background: rgba(32, 33, 36, 0.06);

  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 100;
`;

async function loadOdiaDict() {
  if (!window.typo) {
    const affData = await fetch("/or.aff").then((res) => res.text());
    const dicData = await fetch("/or.dic").then((res) => res.text());
    window.typo = new Typo("or", affData, dicData);
  }

  return window.typo;
}

async function addOdiaSpellchecker(cmInstance) {
  const typo = await loadOdiaDict();
  const nonWord = '!"#$%&()*+,-./:;<=>?@[\\]^_`{|}~ ';

  cmInstance.addOverlay({
    name: "odia-spellchecker",
    token: function (stream) {
      let ch = stream.peek();
      let word = "";

      if (nonWord.includes(ch)) {
        stream.next();
        return null;
      }

      while ((ch = stream.peek()) != null && !nonWord.includes(ch)) {
        word += ch;
        stream.next();
      }

      if (!typo.check(word)) return "spell-error";

      return null;
    },
  });
}

const Editor = ({ initialValue, onChange, saving }) => {
  const containerRef = useRef(null);

  const [fsm] = useState(new OdiaFsm());
  const [pos, setPos] = useState(null);
  const [hasHidden, setHasHidden] = useState(false);

  function equalCursor(r1, r2) {
    if (!r1 || !r2) return false;
    return r1.line === r2.line && r1.ch === r2.ch;
  }

  useEffect(() => {
    loadOdiaDict();
    const container = containerRef.current;
    const ed = new SimpleMDE({
      element: container,
      spellChecker: false,
      autofocus: true,
      initialValue,
      previewRender: (md) => converter.makeHtml(md),
      status: false,
      toolbar: [
        "heading-1",
        "heading-2",
        "heading-3",
        "|",
        "bold",
        "italic",
        "strikethrough",
        "|",
        "ordered-list",
        "unordered-list",
        "table",
        "|",
        "preview",
      ],
    });

    function handleKeyDown(_, e) {
      const cursor = ed.codemirror.getCursor();
      const val = ed.value();
      if (val === "" && !hasHidden) {
        fsm.reset();
      }
      let newHasHidden = false;

      if (e.ctrlKey && e.keyCode === 32) {
        e.preventDefault();
        fsm.toggleLang();
        setHasHidden(newHasHidden);
        return;
      }

      if (e.altKey || e.ctrlKey || e.metaKey) {
        setHasHidden(newHasHidden);
        setPos(cursor);
        return;
      }

      const char = e.key;
      if (char === "Shift") return;
      if (char === " ") {
        fsm.reset();
        return;
      }

      if (char.length !== 1) {
        if (!equalCursor(cursor, pos) || char.startsWith("Arrow")) {
          fsm.reset();
        }
        setPos(cursor);
        setHasHidden(newHasHidden);
        return;
      }

      e.preventDefault();
      e.stopPropagation();
      let [dist, str] = fsm.input(char);
      if (str === null) {
        newHasHidden = true;
        str = "";
      }

      const { line, ch } = cursor;
      ed.codemirror.replaceRange(str, { line, ch: ch + dist }, { line, ch });
      onChange(ed.value());
      setPos(ed.codemirror.getCursor());
      setHasHidden(newHasHidden);
    }

    function handleChange() {
      onChange(ed.value());
    }

    // Add spellchecker logic
    addOdiaSpellchecker(ed.codemirror);

    // Add event listeners

    ed.codemirror.on("keydown", handleKeyDown);
    ed.codemirror.on("change", handleChange);

    return () => {
      ed.toTextArea();
    };
  }, []);

  return (
    <>
      <EditorContainer onClick={() => fsm.reset()}>
        <EditorTextarea ref={containerRef}></EditorTextarea>
        <SavingContainer>{saving ? "saving ..." : "saved"} </SavingContainer>
      </EditorContainer>
    </>
  );
};

export default Editor;
