import React, { useContext } from "react";
import styled from "styled-components";
import Typography from "@mui/material/Typography";

import { AuthContext, useAuthRedirects } from "../App";
import LogoImage from "../images/logo.svg";
import { Button } from "@mui/material";
import { $post } from "../lib/requests";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
`;

const Logo = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 16px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default function UnauthorisedScreen() {
  useAuthRedirects();
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  async function handleLogout() {
    await $post("/logout");
    setAuth({});
    navigate("/login");
  }

  return (
    <Container>
      <LogoContainer>
        <Logo src={LogoImage} alt="logo" width="32px" height="32px" />
        <Typography variant="h4" noWrap component="div">
          ପାଠାଗାର
        </Typography>
      </LogoContainer>
      <p>UNAUTHORISED</p>
      <Button onClick={handleLogout}>Logout</Button>
    </Container>
  );
}
