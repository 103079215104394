import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Shell from "./Shell";
import Header from "./Header";
import { useParams } from "react-router-dom";
import LeftSidebar from "./LeftSidebar";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { IconButton } from "@mui/material";
import WidthFullTwoToneIcon from "@mui/icons-material/WidthFullTwoTone";
import WidthNormalTwoToneIcon from "@mui/icons-material/WidthNormalTwoTone";
import LinearProgress from "@mui/material/LinearProgress";
import AutoModeIcon from "@mui/icons-material/AutoMode";

import Editor from "./OdiaRtfEditor";
import { useDebouncedEffect } from "../lib/useDebouncedEffect";
import { $post, $get } from "../lib/requests";
import { useAuthRedirects } from "../App";

const Heading = styled.h1`
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  padding: 4px 16px;
  border-radius: 16px;
  background: rgba(32, 33, 36, 0.1);
`;

export function BookViewer() {
  const authed = useAuthRedirects();
  const { bookid } = useParams();
  const [book, setBook] = useState({});

  async function getBook() {
    const { book } = await $get(`/api/book/${bookid}`);
    setBook(book);
  }

  useEffect(() => {
    getBook();
  }, []);

  if (!authed) return null;

  return (
    <Shell
      header={<Header toolbar={<Heading>{book.name}</Heading>} />}
      leftSidebar={<LeftSidebar open={false} />}
      main={<BookViewerMain book={book} />}
    />
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
`;

const Viewer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  border-right: 1px rgba(32, 33, 36, 0.1) solid;
  width: 760px;
  max-width: 720px;
  min-width: 720px;
  flex: 0;
`;

const ViewerHeader = styled.div`
  height: 64px;
  border-bottom: 1px rgba(32, 33, 36, 0.1) solid;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  padding: 0 16px;
`;

export const Divider = styled.div`
  height: 100%;
  width: 1px;
  background: rgba(32, 33, 36, 0.1);
`;

const ViewerBody = styled.div`
  flex-grow: 1;
  height: calc(100vh - 130px);
  overflow: auto;
  padding: 16px;
  background: rgba(32, 33, 36, 0.3);

  display: flex;
  align-items: flex-start;
  justify-content: center;

  & > canvas {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const EditorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
`;

const EditorLoadingContainer = styled.div`
  flex-grow: 1;
  padding-top: 63px;
`;

export function openExternal(filename) {
  const { origin } = window.location;
  window.open(`${origin}/pdf/${filename}`);
}

function BookViewerMain({ book }) {
  const { filename, bookid } = book;
  const pdfUrl = `/pdf/${filename}`;

  const [pageNumber, setPageNumber] = useState(1);
  const [pdf, setPdf] = useState(null);
  const [scale, setScale] = useState(1);
  const [fit, setFit] = useState("page");
  const [content, setContent] = useState("");
  const [saving, setSaving] = useState(false);
  const [editorLoading, setEditorLoading] = useState(false);

  const canvasContainerRef = useRef(null);

  async function getPdf() {
    const pdfDoc = await window.pdfjsLib.getDocument(pdfUrl).promise;
    setPdf(pdfDoc);
    return pdfDoc;
  }

  function getScale(viewport) {
    if (fit === "page") {
      const desiredHeight = canvasContainerRef.current.offsetHeight - 32;
      return Math.floor((desiredHeight / viewport.height) * 100) / 100;
    }

    if (fit === "width") {
      const desiredWidth = canvasContainerRef.current.offsetWidth - 32;
      return Math.floor((desiredWidth / viewport.width) * 100) / 100;
    }

    return scale;
  }

  async function renderPdf(pdfDoc = pdf) {
    if (!pdfDoc) return;

    const page = await pdfDoc.getPage(pageNumber);
    const viewport = page.getViewport({ scale: 1 });
    const finalScale = getScale(viewport);
    setScale(finalScale);
    const scaledViewport = page.getViewport({ scale: finalScale });

    while (canvasContainerRef.current.firstChild) {
      canvasContainerRef.current.removeChild(
        canvasContainerRef.current.lastChild
      );
    }
    const canvas = document.createElement("canvas");
    canvasContainerRef.current.appendChild(canvas);

    const canvasContext = canvas.getContext("2d");
    canvas.height = scaledViewport.height;
    canvas.width = scaledViewport.width;

    const renderContext = {
      canvasContext,
      viewport: scaledViewport,
    };
    const renderTask = page.render(renderContext);
    renderTask.promise.then(function () {
      // console.log("Page rendered");
    });
  }

  useEffect(() => {
    if (pdf) {
      renderPdf();
    }
  }, [pageNumber, scale, fit]);

  async function getExtractedText(regenerate = false) {
    setEditorLoading(true);
    const { extracted } = await $post(`/api/getText`, {
      bookid,
      pageNumber,
      regenerate,
    });
    setContent(extracted);
    setEditorLoading(false);
  }

  async function handleRegenerate() {
    getExtractedText(true);
  }

  useEffect(() => {
    getExtractedText();
  }, [pageNumber, book]);

  useEffect(() => {
    if (book.filename) getPdf().then((pdfDoc) => renderPdf(pdfDoc));
  }, [book]);

  async function saveContent() {
    const { saved } = await $post(`/api/saveContent`, {
      bookid,
      pageNumber,
      content,
    });
    setSaving(!saved);
  }

  useDebouncedEffect(
    () => {
      saveContent();
    },
    [content],
    2000
  );

  useEffect(() => {
    setSaving(true);
  }, [content]);

  function onPageChange(pageNo) {
    setPageNumber(pageNo);
  }

  function onScaleChange(val) {
    setScale(val);
    setFit(null);
  }

  function toggleFit() {
    if (fit === "page") setFit("width");
    else setFit("page");
  }

  return (
    <Container>
      <Viewer>
        <ViewerHeader>
          <PdfPagination
            count={book.numberOfPages}
            onChange={onPageChange}
            page={pageNumber}
            onRegenerateClick={handleRegenerate}
          />
          <Divider />
          <PdfScale
            scale={scale}
            onChange={onScaleChange}
            fit={fit}
            onFitClick={toggleFit}
          />
          <Divider />
          <IconButton size="small" onClick={() => openExternal(filename)}>
            <OpenInNewIcon fontSize="small" />
          </IconButton>
        </ViewerHeader>
        <ViewerBody ref={canvasContainerRef} />
      </Viewer>
      <EditorContainer>
        {editorLoading ? (
          <EditorLoadingContainer>
            <LinearProgress />
          </EditorLoadingContainer>
        ) : (
          <Editor
            initialValue={content}
            onChange={(md) => setContent(md)}
            saving={saving}
          />
        )}
      </EditorContainer>
    </Container>
  );
}

const Label = styled.span`
  font-size: 16px;
`;

const PdfPaginationContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-right: auto;
`;

function PdfPagination({ count, page, onChange, onRegenerateClick }) {
  return (
    <PdfPaginationContainer>
      <Label>ପୃଷ୍ଠା</Label>
      <NumberInput value={page} onChange={onChange} min={1} max={count} />
      <Label>ସମୁଦାୟ {count}</Label>
      <IconButton size="small" onClick={onRegenerateClick}>
        <AutoModeIcon fontSize="small" />
      </IconButton>
    </PdfPaginationContainer>
  );
}

function PdfScale({ scale, onChange, fit, onFitClick }) {
  function toPercent(num) {
    return Math.floor(num * 100);
  }

  function handleChange(num) {
    onChange(num / 100);
  }

  return (
    <>
      <Label>ଆକାର</Label>
      <NumberInput
        value={toPercent(scale)}
        onChange={handleChange}
        min={1}
        max={500}
        step={25}
      />
      <IconButton size="small" onClick={onFitClick}>
        {fit === "page" ? (
          <WidthFullTwoToneIcon fontSize="small" />
        ) : (
          <WidthNormalTwoToneIcon fontSize="small" />
        )}
      </IconButton>
    </>
  );
}

const NumberInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px;

  height: 38px;
  width: 100px;
  border-radius: 4px;
  border: 1px rgba(32, 33, 36, 0.1) solid;
  color: rgba(32, 33, 36, 0.6);

  & > svg {
    cursor: pointer;
    &:hover,
    &:focus {
      color: black;
    }
  }
`;

const NumInput = styled.input.attrs({ type: "number" })`
  height: 32px;
  text-align: center;
  border: none;
  width: 48px;
  font-size: 16px;
  color: black;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  &:focus,
  &:active {
    border: none;
    outline: none;
  }
`;

function NumberInput({ value, onChange, min = 1, max, step = 1 }) {
  const [val, setVal] = useState(value);

  useEffect(() => {
    setVal(value);
  }, [value]);

  function handleChange(e) {
    setVal(e.target.value);

    const num = parseInt(e.target.value);
    if (!!num && num >= min && num <= max) onChange(num);
    if (num < min || num > max) setVal(value);
  }

  function inc() {
    if (!val) {
      setVal(min);
      return onChange(min);
    }
    const num = parseInt(val) || 0;
    const lastStep = num - (num % step);
    onChange(Math.min(lastStep + step, max));
  }

  function dec() {
    if (!val) {
      setVal(min);
      return onChange(min);
    }
    const num = parseInt(val) || 0;
    const lastStep = num - (num % step);
    onChange(Math.max(min, lastStep === num ? lastStep - step : lastStep));
  }

  return (
    <NumberInputContainer>
      <ArrowBackIosIcon fontSize="small" onClick={dec} />
      <NumInput value={val} min={min} max={max} onChange={handleChange} />
      <ArrowForwardIosIcon fontSize="small" onClick={inc} />
    </NumberInputContainer>
  );
}
