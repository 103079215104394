import React, { useState, useLayoutEffect, useContext, useEffect } from "react";
import styled from "styled-components";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { QueryContext, useAuthRedirects, AuthContext } from "../App";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { qurl } from "../lib/urlgen";
import { useDebouncedEffect } from "../lib/useDebouncedEffect";
import { $get, $post } from "../lib/requests";
import { openExternal } from './BookViewer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 64px;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px rgba(32, 33, 36, 0.1) solid;
`;

const MainContainer = styled.div`
  flex-grow: 1;
  max-height: 100%;
  overflow: auto;
  height: calc(100vh - 130px);
  padding: 16px;
`;

const ItemsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FlexCell = styled.div`
  min-width: 224px;
  width: 20%;
  display: flex;
  justify-content: center;
  padding: 12px 0;
`;

const BookCardContainer = styled.div`
  width: 200px;
  background: ${(props) => (props.loading ? "rgba(32,33,36,0.1)" : "white")};

  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 1px rgba(32, 33, 36, 0.1) solid;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 8px rgba(32, 33, 36, 0.1);
    transform: scale(102%);
  }

  h4 {
    font-size: 16px;
    margin: 2px 0;
  }

  p,
  div {
    font-size: 12px;
    margin: 0;
  }

  a {
    text-decoration: none;
    color: black;

    &:hover {
      color: blue;
    }
  }
`;

const CoverImage = styled.div`
  height: 180px;
  margin-bottom: 24px;
  text-align: center;

  img {
    width: 120px;
    height: auto;
    max-height: 180px;
  }
`;

const Category = styled.span`
  margin: 2px 2px 0 0;
  display: inline-flex;
  align-items: center;

  padding: 4px 8px;
  height: 20px;
  border-radius: 10px;
  background: rgba(32, 33, 36, 0.1);

  &:hover {
    background: rgba(32, 33, 36, 0.2);
  }
`;

const ValignContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const OrderToggle = styled.button`
  cursor: pointer;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 4px 8px;
  background: transparent;
  font-size: 16px;

  border-radius: 4px;
  border: 1px rgba(32, 33, 36, 0.3) solid;
`;

const Label = styled.span`
  font-size: 16px;
`;

const CollectionContainer = styled.div`
  padding: 4px 16px;
  border-radius: 8px;
  border: 1px rgba(32, 33, 36, 0.1) solid;
  text-decoration: none;
  color: black;
  margin-right: 16px;
  margin-bottom: 16px;

  h4,
  p {
    margin: 0;
  }
  p {
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
  }

  &:hover {
    box-shadow: 0 0 8px rgba(32, 33, 36, 0.1);
    transform: scale(110%);
  }
`;

function withSeparator(sep, arr) {
  const out = [];
  for (let i = 0; i < arr.length - 1; i++) {
    out.push(arr[i]);
    out.push(sep);
  }
  if (arr.length) out.push(arr[arr.length - 1]);

  return out;
}

function BookCard({ book }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);

  function openGcsPage(e) {
    if (e.target.nodeName === "A" || e.target.nodeName === "SPAN") return;
    window.open(
      `https://console.cloud.google.com/storage/browser/_details/odia-books-scanned/books/pdf/${book.filename}`
    );
  }

  async function openCachedPdf(e) {
    if (e.target.nodeName === "A" || e.target.nodeName === "SPAN") return;

    const { filename } = book;
    setLoading(true);
    const { filepath } = await $post(`/api/cachedPath`, { filename });
    setLoading(false);
    if (!auth.isAdmin) {
      openExternal(filename);
      return;
    }
    if (filepath) navigate(`/book/${book.bookid}`, { target: "_blank" });
  }

  return (
    <FlexCell>
      <BookCardContainer
        data-bookid={book.bookid}
        onClick={openCachedPdf}
        loading={loading}
      >
        <CoverImage>
          <img src={`thumbnails/${encodeURIComponent(book.coverImage)}`} alt={book.name} />
        </CoverImage>
        <h4>{book.name}</h4>
        <p>
          {withSeparator(
            ", ",
            book.authors.map((x) => (
              <Link to={qurl("ଲେଖକ", x.name)}>
                {x.name}
                {x.meta && ` (${x.meta})`}
              </Link>
            ))
          )}
        </p>
        <p>
          {withSeparator(
            ", ",
            book.years.map((x) => (
              <Link to={qurl("ବର୍ଷ", x.year)}>{x.year}</Link>
            ))
          )}
        </p>
        <div>
          {book.categories.map((x) => (
            <Link to={qurl("ବିଷୟ", x)}>
              <Category>{x}</Category>
            </Link>
          ))}
        </div>
      </BookCardContainer>
    </FlexCell>
  );
}

function getUrl(item, type) {
  if (type === "authors") return qurl("ଲେଖକ", item.name);
  if (type === "categories") return qurl("ବିଷୟ", item.name);
  if (type === "years") return qurl("ବର୍ଷ", item.year);
  return "";
}

function CollectionCard({ item, type }) {
  return (
    <Link to={getUrl(item, type)} style={{ textDecoration: "none" }}>
      <CollectionContainer>
        <h4>{item.name || item.year || ""}</h4>
        <p>{item.bookCount}</p>
      </CollectionContainer>
    </Link>
  );
}

const SORT_BY_FIELDS = {
  books: ["ନାମ", "ଲେଖକ", "ବିଷୟ", "ବର୍ଷ"],
  authors: ["ବହି ସଂଖ୍ୟା", "ନାମ"],
  categories: ["ବହି ସଂଖ୍ୟା", "ନାମ"],
  years: ["ବହି ସଂଖ୍ୟା", "ବର୍ଷ"],
};

const PAGE_TYPE = {
  ଲେଖକ: "authors",
  ବିଷୟ: "categories",
  ବର୍ଷ: "years",
};

export default function MainArea() {
  const authed = useAuthRedirects();
  // console.log({ authed });
  const { search } = useLocation();
  const searchValue = new URLSearchParams(search).get("q");

  const [pageType, setPageType] = useState(PAGE_TYPE[searchValue] || "books");
  const [cardType, setCardType] = useState(
    pageType === "books" ? "book" : "collection"
  );
  const [items, setItems] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [sortOrder, setSortOrder] = useState({
    by: SORT_BY_FIELDS[pageType][0],
    order: SORT_BY_FIELDS[pageType][0] === "ବହି ସଂଖ୍ୟା" ? "desc" : "asc",
  });
  const [count, setCount] = useState(0);
  const { searchString, fixedQuery, setFixedQuery, setSearchString } =
    useContext(QueryContext);

  async function getBooks() {
    const res = await $get(
      `/api/books?page=${curPage}&sortBy=${sortOrder.by}&sortOrder=${
        sortOrder.order
      }${fixedQuery ? `&fq=${fixedQuery}` : ""}&q=${searchString || ""}`
    );
    setItems(res.books);
    setCount(res.numBooks);
    setCardType("book");
  }

  async function getAuthors() {
    const res = await $get(
      `/api/authors?page=${curPage}&sortBy=${sortOrder.by}&sortOrder=${
        sortOrder.order
      }&q=${searchString || ""}`
    );
    setItems(res.authors);
    setCount(res.numAuthors);
    setCardType("collection");
  }

  async function getCategories() {
    const res = await $get(
      `/api/categories?page=${curPage}&sortBy=${sortOrder.by}&sortOrder=${
        sortOrder.order
      }&q=${searchString || ""}`
    );
    setItems(res.categories);
    setCount(res.numCategories);
    setCardType("collection");
  }

  async function getYears() {
    const res = await $get(
      `/api/years?page=${curPage}&sortBy=${sortOrder.by}&sortOrder=${
        sortOrder.order
      }&q=${searchString || ""}`
    );
    setItems(res.years);
    setCount(res.numYears);
    setCardType("collection");
  }

  async function getData() {
    if (pageType === "authors") {
      await getAuthors();
    } else if (pageType === "categories") {
      await getCategories();
    } else if (pageType === "years") {
      await getYears();
    } else {
      await getBooks();
    }
  }

  function onPageChange(e, pageNo) {
    setCurPage(pageNo);
  }

  function onSortByChange(e) {
    setSortOrder({
      by: e.target.value,
      order: e.target.value === "ବହି ସଂଖ୍ୟା" ? "desc" : "asc",
    });
  }

  function toggleSortOrder() {
    setSortOrder({
      by: sortOrder.by,
      order: sortOrder.order === "asc" ? "desc" : "asc",
    });
  }

  useDebouncedEffect(
    () => {
      getData();
    },
    [pageType, fixedQuery, searchString, curPage, sortOrder],
    100
  );

  useLayoutEffect(() => {
    setCurPage(1);
  }, [searchString]);

  useEffect(() => {
    setFixedQuery(searchValue);
    setSearchString("");
    setCurPage(1);
    setPageType(PAGE_TYPE[searchValue] || "books");
  }, [searchValue]);

  useEffect(() => {
    setSortOrder({
      by: SORT_BY_FIELDS[pageType][0],
      order: SORT_BY_FIELDS[pageType][0] === "ବହି ସଂଖ୍ୟା" ? "desc" : "asc",
    });
  }, [pageType]);

  const pageSize = pageType === "books" ? 50 : 100;
  const startIndex = (curPage - 1) * pageSize + 1;
  const endIndex = Math.min(curPage * pageSize, count);
  const numPages = Math.ceil(count / pageSize);

  if (!authed) return null;

  return (
    <Container>
      <HeaderContainer>
        <ValignContainer>
          <Label>ଶୃଙ୍ଖଳା</Label>
          <FormControl size="small">
            <Select
              value={sortOrder.by}
              onChange={onSortByChange}
              variant="outlined"
            >
              {SORT_BY_FIELDS[pageType].map((option) => (
                <MenuItem value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <OrderToggle onClick={toggleSortOrder}>
            {sortOrder.order === "asc" ? (
              <>
                <ArrowUpwardIcon fontSize="small" />
                <span>ଉର୍ଦ୍ଧ୍ଵ କ୍ରମ</span>
              </>
            ) : (
              <>
                <ArrowDownwardIcon fontSize="small" />
                <span>ଅଧଃ କ୍ରମ</span>
              </>
            )}
          </OrderToggle>
        </ValignContainer>
        <ValignContainer>
          <Label>
            {startIndex}-{endIndex}, ସମୁଦାୟ {count}
          </Label>
          <Pagination
            count={numPages}
            shape="rounded"
            onChange={onPageChange}
            page={curPage}
          />
        </ValignContainer>
      </HeaderContainer>
      <MainContainer>
        <ItemsGrid>
          {items.map((item, index) =>
            cardType === "book" ? (
              <BookCard book={item} />
            ) : (
              <CollectionCard item={item} type={pageType}></CollectionCard>
            )
          )}
        </ItemsGrid>
      </MainContainer>
    </Container>
  );
}
