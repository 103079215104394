import React, { useContext, useEffect, useState, useLayoutEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

import MenuBookIcon from "@mui/icons-material/MenuBook";
import FaceIcon from "@mui/icons-material/Face";
import AddIcon from "@mui/icons-material/Add";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CategoryIcon from "@mui/icons-material/Category";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import { useShell } from "./Shell";
import { qurl } from "../lib/urlgen";
import { AuthContext } from "../App";

const Container = styled.ul`
  width: 100%;
  height: calc(100vh - 130px);
  overflow-y: auto;
  margin: 0;
  padding-top: 8px;
  padding-left: 0;
  padding-right: 8px;

  a {
    text-decoration: none;
    color: rgb(32, 33, 36);

    &:hover,
    &:focus,
    &:active {
      color: rgb(32, 33, 36);
    }
  }
`;

const TopContainer = styled(Container)`
  height: 64px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
`;

const Li = styled.li`
  position: relative;
  list-style-type: none;
  height: 36px;
  width: 100%;

  display: flex;
  align-items: center;

  padding-left: ${(props) => (props.open ? "26px" : "20px")};
  padding-right: ${(props) => (props.open ? "12px" : "12px")};

  border-radius: 0 18px 18px 0;
  cursor: pointer;

  font-weight: ${(props) => (props.active ? "600" : "normal")};
  background: ${(props) =>
    props.active && props.open ? "#d3e3fd" : "transparent"};

  &:hover {
    ${(props) =>
      props.open && !props.active ? "background: rgba(32, 33, 36, 0.06);" : ""}
  }
`;

const NewBookContainer = styled.div`
  margin-top: 1px;
  height: 64px;
  border-bottom: 1px rgba(32, 33, 36, 0.1) solid;
  width: 100%;
`;

const NewBookButton = styled.button`
  height: 56px;
  border-radius: 16px;
  color: #001d35;
  font-size: 16px;
  font-weight: 600;

  padding-right: ${(props) => (props.open ? "24px" : "16px")};
  padding-left: 16px;

  background: #c2e7ff;
  border: none;
  box-shadow: 0 0 2px rgba(32, 33, 36, 0.06);
  cursor: pointer;

  display: flex;
  align-items: center;

  span {
    margin-left: 8px;
    margin-top: 2px;
  }

  &:hover {
    box-shadow: 0 0 8px rgba(32, 33, 36, 0.06);
  }
`;

const IconContainer = styled.div`
  height: ${(props) => (props.open ? "20px" : "32px")};
  width: ${(props) => (props.open ? "20px" : "32px")};
  margin-right: ${(props) => (props.open ? "12px" : "0")};
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ${(props) => (!props.open ? "16px" : "0")};
  background: ${(props) =>
    !props.open && props.active ? "#d3e3fd" : "transparent"};

  &:hover {
    ${(props) =>
      !(props.open || props.active)
        ? "background: rgba(32, 33, 36, 0.06);"
        : ""}
  }
`;

const LabelContainer = styled.div``;

const MetaContainer = styled.div`
  margin-left: auto;
`;

const ExpandIconContainer = styled.div`
  position: absolute;
  top: 9px;
  left: 6px;
`;

function Line({
  icon = <LabelImportantIcon fontSize="small" />,
  href = "",
  label,
  meta,
  onClick = () => 0,
  expandable = false,
  expanded = false,
}) {
  const { pathname, search } = useLocation();
  const active = window.encodeURI(href) === pathname + search;
  const { getState } = useShell();
  const { isLeftSidebarOpen: isOpen } = getState();

  const x = (
    <Link to={href} onClick={onClick}>
      <Li active={active} open={isOpen}>
        {isOpen && expandable && (
          <ExpandIconContainer>
            {expanded ? (
              <ArrowDropDownIcon fontSize="small" />
            ) : (
              <ArrowRightIcon fontSize="small" />
            )}
          </ExpandIconContainer>
        )}
        <IconContainer open={isOpen} active={active}>
          {icon}
        </IconContainer>
        {isOpen && (
          <>
            <LabelContainer>{label}</LabelContainer>
            {meta && <MetaContainer>{meta}</MetaContainer>}
          </>
        )}
      </Li>
    </Link>
  );

  // console.log(x);
  return x;
}

export default function LeftSidebar({ open = true }) {
  const [data, setData] = useState({});
  const [authorsExpanded, setAuthorsExpanded] = useState(false);
  const [categoriesExpanded, setCategoriesExpanded] = useState(true);
  const [yearsExpanded, setYearsExpanded] = useState(false);
  const { auth } = useContext(AuthContext);

  const location = useLocation();
  useLayoutEffect(() => {
    const { pathname, search } = location;
    const url = pathname + search;

    const isAuthorPage = data.topAuthors
      ? !!data.topAuthors.find(
          ({ name }) => window.encodeURI(qurl("ଲେଖକ", name)) === url
        )
      : window.encodeURI(qurl("ଲେଖକ")) === url;
    const isCategoryPage = data.topCategories
      ? !!data.topCategories.find(
          ({ name }) => window.encodeURI(qurl("ବିଷୟ", name)) === url
        )
      : window.encodeURI(qurl("ବିଷୟ")) === url;
    const isYearPage = data.topYears
      ? !!data.topYears.find(
          ({ year }) => window.encodeURI(qurl("ବର୍ଷ", year)) === url
        )
      : window.encodeURI(qurl("ବର୍ଷ")) === url;

    if (isAuthorPage) setAuthorsExpanded(true);
    if (isCategoryPage) setCategoriesExpanded(true);
    if (isYearPage) setYearsExpanded(true);
  }, [location, data]);

  const { getState, setLeftSidebarOpen } = useShell();
  const { isLeftSidebarOpen: isOpen } = getState();

  function toggleAuthors(e) {
    e.preventDefault();
    setAuthorsExpanded((x) => !x);
  }

  function toggleCategories(e) {
    e.preventDefault();
    setCategoriesExpanded((x) => !x);
  }

  function toggleYears(e) {
    e.preventDefault();
    setYearsExpanded((x) => !x);
  }

  useEffect(() => {
    setLeftSidebarOpen(open);
    fetch("/api/sidebar")
      .then((res) => res.json())
      .then((x) => setData(x));
  }, []);

  return (
    <>
      <NewBookContainer>
        <TopContainer open={isOpen}>
          <Line
            icon={<MenuBookIcon fontSize="small" />}
            label="ସମସ୍ତ ପୁସ୍ତକ"
            href="/"
            meta={data.numBooks || "-"}
          />
        </TopContainer>
      </NewBookContainer>
      <Container open={isOpen}>
        <Line
          icon={<CategoryIcon fontSize="small" />}
          label="ବିଷୟ"
          onClick={toggleCategories}
          expandable={true}
          expanded={categoriesExpanded}
          meta={data.numCategories || "-"}
        />
        {categoriesExpanded && (
          <>
            {data.topCategories &&
              data.topCategories.map(({ name, bookCount }) => (
                <Line label={name} meta={bookCount} href={qurl("ବିଷୟ", name)} />
              ))}
            <Line
              label="ସମସ୍ତ ବିଷୟ"
              href={qurl("ବିଷୟ")}
              icon={<ArrowForwardIcon fontSize="small" />}
            />
          </>
        )}
        <Line
          icon={<FaceIcon fontSize="small" />}
          label="ଲେଖକ"
          onClick={toggleAuthors}
          expandable={true}
          expanded={authorsExpanded}
          meta={data.numAuthors || "-"}
        />
        {authorsExpanded && (
          <>
            {data.topAuthors &&
              data.topAuthors.map(({ name, bookCount }) => (
                <Line label={name} meta={bookCount} href={qurl("ଲେଖକ", name)} />
              ))}
            <Line
              label="ସମସ୍ତ ଲେଖକ"
              href={qurl("ଲେଖକ")}
              icon={<ArrowForwardIcon fontSize="small" />}
            />
          </>
        )}
        <Line
          icon={<CalendarMonthIcon fontSize="small" />}
          label="ବର୍ଷ"
          onClick={toggleYears}
          expandable={true}
          expanded={yearsExpanded}
          meta={data.numYears || "-"}
        />
        {yearsExpanded && (
          <>
            {data.topYears &&
              data.topYears.map(({ year, bookCount }) => (
                <Line label={year} meta={bookCount} href={qurl("ବର୍ଷ", year)} />
              ))}
            <Line
              label="ସମସ୍ତ ବର୍ଷ"
              href={qurl("ବର୍ଷ")}
              icon={<ArrowForwardIcon fontSize="small" />}
            />
          </>
        )}
      </Container>
    </>
  );
}

// rgb(211, 227, 253)
// rgb(225, 227, 225)
// rgba(32, 33, 36, 0,06)
// rgba(60,64,67,0.08);
