import React, { useContext, useLayoutEffect } from "react";
import styled from "styled-components";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { $post } from "../lib/requests";
import { useNavigate } from "react-router-dom";
import { AuthContext, useAuthRedirects } from "../App";

const LoginScreenContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function LoginScreen() {
  useAuthRedirects();
  const navigate = useNavigate();
  const { setAuth } = useContext(AuthContext);

  async function handleGoogleLogin(response) {
    const { isLoggedIn, user, unauthorised } = await $post("/login", response);
    setAuth({
      user,
      isLoggedIn,
      unauthorised,
    });
    if (unauthorised) return navigate("/401");
    if (isLoggedIn) {
      return navigate("/");
    }
  }

  return (
    <GoogleOAuthProvider clientId="1051633168139-9osina7da7509m5st6jqt3f2tar7luub.apps.googleusercontent.com">
      <LoginScreenContainer>
        <GoogleLogin
          theme="outline"
          type="standard"
          size="large"
          shape="pill"
          logo_alignment="left"
          locale="en_GB"
          ux_mode="popup"
          context="signin"
          onSuccess={handleGoogleLogin}
          onError={() => {
            console.log("Login Failed");
          }}
        />
      </LoginScreenContainer>
    </GoogleOAuthProvider>
  );
}
