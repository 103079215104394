import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import OdiaInput from "./OdiaInput";
import { QueryContext } from "../App";

const SearchContainer = styled.div`
  max-width: 720px;
  margin-right: 40px;
  width: 40%;
  height: 44px;
  flex-grow: 1;
  border-radius: 22px;
  background: rgb(234, 241, 251);
  border: 1px rgba(0, 0, 0, 0.1) solid;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const SearchInputContainer = styled.div`
  width: 100%;
  height: 44px;

  display: flex;
  align-items: center;
  padding-right: 4px;

  input {
    flex-grow: 1;
    height: 32px;
    font-size: 16px;
    background: transparent;
    border: none;

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }
`;

const SearchIconContainer = styled.div`
  width: 48px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ClearIconContainer = styled.div`
  margin-top: -2px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const FixedQuery = styled.span`
  padding: 2px 8px;
  border-radius: 4px;
  margin-right: 16px;
  background: rgba(32, 33, 36, 0.1);
  font-weight: bold;
  margin-top: -2px;
`;

export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  const { searchString, setSearchString, fixedQuery } =
    useContext(QueryContext);

  function clearSearch() {
    setSearchString("");
  }

  function handleSearchChange(str) {
    setSearchString(str);
  }

  return (
    <SearchContainer open={isOpen}>
      <SearchInputContainer>
        <SearchIconContainer>
          <SearchIcon />
        </SearchIconContainer>
        {fixedQuery && <FixedQuery>{fixedQuery}</FixedQuery>}
        <OdiaInput
          type="text"
          placeholder="ଅନୁସନ୍ଧାନ"
          onChange={handleSearchChange}
          value={searchString}
        />
        {searchString && (
          <ClearIconContainer role="button" onClick={clearSearch}>
            <ClearIcon />
          </ClearIconContainer>
        )}
      </SearchInputContainer>
    </SearchContainer>
  );
}
