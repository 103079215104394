import { ThemeProvider } from "@mui/material/styles";
import React, {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";

import Shell from "./components/Shell";

import Header from "./components/Header";
import LeftSidebar from "./components/LeftSidebar";
import MainArea from "./components/MainArea";
import { BookViewer } from "./components/BookViewer";
import LoginScreen from "./components/LoginScreen";
import UnauthorisedScreen from "./components/UnauthorisedScreen";
import { $post } from "./lib/requests";

export const QueryContext = createContext({});
export const AuthContext = createContext({});

export function useAuthRedirects() {
  const { isLoggedIn, unauthorised } = useContext(AuthContext).auth;
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!isLoggedIn) navigate("/login");
    else if (unauthorised) navigate("/401");
    else if (pathname === "/login" || pathname === "/401") navigate("/");
  }, []);

  return isLoggedIn && !unauthorised;
}

function App() {
  const [searchString, setSearchString] = useState("");
  const [fixedQuery, setFixedQuery] = useState("");
  const [auth, setAuth] = useState(null);
  const [loading, setLoading] = useState(true);

  function resetSearchState() {
    setSearchString("");
    setFixedQuery("");
  }

  async function autoAuth() {
    const authState = await $post("/login", {});
    setAuth(authState);
    setLoading(false);
  }

  useLayoutEffect(() => {
    autoAuth();
  }, []);

  if (loading) return <div />;

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <QueryContext.Provider
          value={{
            searchString,
            setSearchString,
            fixedQuery,
            setFixedQuery,
            resetSearchState,
          }}
        >
          <Shell
            header={<Header />}
            leftSidebar={<LeftSidebar />}
            rightSidebar={null}
            main={<MainArea />}
          />
        </QueryContext.Provider>
      ),
    },
    {
      path: "/book/:bookid",
      element: <BookViewer />,
    },
    {
      path: "/login",
      element: <LoginScreen />,
    },
    {
      path: "/401",
      element: <UnauthorisedScreen />,
    },
  ]);

  return (
    <ThemeProvider theme={{}}>
      <AuthContext.Provider value={{ auth, setAuth }}>
        <RouterProvider router={router} />
      </AuthContext.Provider>
    </ThemeProvider>
  );
}

export default App;

// rgb(234, 241, 251)
