import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";

import { useShell } from "./Shell";
import LogoImage from "../images/logo.svg";
import Searchbar from "./Searchbar";
import { Button } from "@mui/material";
import { AuthContext } from "../App";
import { $post } from "../lib/requests";
import { Link, useNavigate } from "react-router-dom";

const Toolbar = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 16px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 232px;

  .logo-link {
    display: flex;
    align-items: center;
    text-decoration: none;
  }
`;

const Logo = styled.img`
  width: 32px;
  height: 32px;
  margin: 8px;
`;

const SettingsIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  position: relative;
`;

const SettingsPopup = styled.div`
  padding: 20px;
  border-radius: 8px;
  background: white;
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  border: 1px rgba(0, 0, 0, 0.1) solid;
  box-shadow: 0 0 8px rgba(32, 33, 36, 0.2);
  z-index: 100;

  display: ${(props) => (props.open ? "block" : "none")};
`;

const UserDropdownBtn = styled.button`
  border: 2px black solid;
  border-radius: 18px;
  width: 36px;
  height: 36px;
  background: white;
  padding: 0;
  overflow: hidden;

  img {
    width: 36px;
    height: 36px;
    margin: 0;
  }
`;

export default function Header({ toolbar = <Searchbar /> }) {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const shellApi = useShell();
  const settingsPopupRef = useRef();
  const { auth, setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    function handleOutsideClick(e) {
      if (
        settingsPopupRef.current &&
        !settingsPopupRef.current.contains(e.target)
      ) {
        setSettingsOpen(false);
      }
    }
    document.body.addEventListener("mousedown", handleOutsideClick);

    return () =>
      document.body.removeEventListener("mousedown", handleOutsideClick);
  }, [settingsPopupRef]);

  function toggleLeftSidebar() {
    if (shellApi.getState().isLeftSidebarOpen) {
      shellApi.setLeftSidebarOpen(false);
    } else {
      shellApi.setLeftSidebarOpen(true);
    }
  }

  function openSettingsPopup() {
    setSettingsOpen((x) => !x);
  }

  async function handleLogout() {
    await $post("/logout");
    setAuth({});
    navigate("/login");
  }

  return (
    <Toolbar>
      <LogoContainer>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          onClick={toggleLeftSidebar}
        >
          <MenuIcon />
        </IconButton>
        <Link to='/' className="logo-link">
          <Logo src={LogoImage} alt="logo" width="32px" height="32px" />
          <Typography variant="h4" noWrap component="div">
            ପାଠାଗାର
          </Typography>
        </Link>
      </LogoContainer>
      {toolbar}
      <SettingsIconContainer ref={settingsPopupRef}>
        <UserDropdownBtn
          onClick={openSettingsPopup}
        >
          { auth.user &&
            <img src={auth.user.picture} alt={auth.user.name} />
          }
        </UserDropdownBtn>
        {/* <IconButton */}
        {/*   size="large" */}
        {/*   edge="start" */}
        {/*   color="inherit" */}
        {/*   onClick={openSettingsPopup} */}
        {/* > */}
        {/*   <SettingsOutlinedIcon /> */}
        {/* </IconButton> */}
        <SettingsPopup open={settingsOpen}>
          <Button onClick={handleLogout}>logout</Button>
        </SettingsPopup>
      </SettingsIconContainer>
    </Toolbar>
  );
}
