import React, { useState, createContext, useContext } from "react";
import styled from "styled-components";

const PageContainer = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const HeaderContainer = styled.header`
  width: 100%;
  height: 64px;
  padding: 8px;
`;

const BodyContainer = styled.div`
  width: 100%;
  flex-grow: 1;

  display: flex;
  align-items: stretch;

  & > * {
    transition: width 0.02s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
`;

const LeftSidebarContainer = styled.aside`
  min-width: ${(props) => (props.open ? "264px" : "72px")};
  width: ${(props) => (props.open ? "264px" : "72px")};
`;

const RightSidebarContainer = styled.div`
  width: ${(props) => (props.open ? "30%" : "0px")};
`;

const MainContainer = styled.main`
  flex-grow: 1;
  border-radius: 16px ${(props) => (props.rightOpen ? "16px" : "0")} 0 0;
  background: white;
  overflow: hidden;
  border: 1px rgba(0, 0, 0, 0.1) solid;
`;

const ShellContext = createContext({});

export default function Shell({ header, leftSidebar, rightSidebar, main }) {
  const [isLeftSidebarOpen, setLeftSidebarOpen] = useState(true);
  const [isRightSidebarOpen, setRightSidebarOpen] = useState(false);

  const api = {
    setLeftSidebarOpen,
    setRightSidebarOpen,
    getState() {
      return { isLeftSidebarOpen, isRightSidebarOpen };
    },
  };

  return (
    <ShellContext.Provider value={api}>
      <PageContainer>
        <HeaderContainer>{header}</HeaderContainer>
        <BodyContainer>
          <LeftSidebarContainer open={isLeftSidebarOpen}>
            {leftSidebar}
          </LeftSidebarContainer>
          <MainContainer rightOpen={isRightSidebarOpen}>{main}</MainContainer>
          <RightSidebarContainer open={isRightSidebarOpen}>
            {rightSidebar}
          </RightSidebarContainer>
        </BodyContainer>
      </PageContainer>
    </ShellContext.Provider>
  );
}

export function useShell() {
  return useContext(ShellContext);
}
